import React from 'react'

const Logo = () => (
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.4464 0.3H14.5536C16.2807 0.3 17.5567 0.300265 18.5636 0.387929C19.5665 0.475255 20.2741 0.64758 20.8704 0.975366C21.7783 1.47451 22.5255 2.22169 23.0246 3.12962C23.3524 3.72586 23.5247 4.43345 23.6121 5.43644C23.6997 6.44329 23.7 7.71934 23.7 9.4464V14.5536C23.7 16.2807 23.6997 17.5567 23.6121 18.5636C23.5247 19.5665 23.3524 20.2741 23.0246 20.8704C22.5255 21.7783 21.7783 22.5255 20.8704 23.0246C20.2741 23.3524 19.5665 23.5247 18.5636 23.6121C17.5567 23.6997 16.2807 23.7 14.5536 23.7H9.4464C7.71934 23.7 6.44329 23.6997 5.43644 23.6121C4.43345 23.5247 3.72586 23.3524 3.12962 23.0246C2.22169 22.5255 1.47451 21.7783 0.975366 20.8704C0.64758 20.2741 0.475255 19.5665 0.387929 18.5636C0.300265 17.5567 0.3 16.2807 0.3 14.5536V9.4464C0.3 7.71934 0.300265 6.44329 0.387929 5.43644C0.475255 4.43345 0.64758 3.72586 0.975366 3.12962C1.47451 2.22169 2.22169 1.47451 3.12962 0.975366C3.72586 0.64758 4.43345 0.475255 5.43644 0.387929C6.44329 0.300265 7.71934 0.3 9.4464 0.3Z" stroke="#323544" stroke-width="0.5"/>
        <path d="M1.77783 1.62964L22.3704 22.3704" stroke="#323544" stroke-width="0.5"/>
        <path d="M22.2222 1.62964L1.62958 22.3704" stroke="#323544" stroke-width="0.5"/>
        <circle cx="12" cy="12" r="7.55185" stroke="#323544" stroke-width="0.5"/>
    </svg>
)

export default Logo
