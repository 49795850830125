import React from 'react';
import styled, { createGlobalStyle } from 'styled-components'
import AppCard from './AppCard'
import Logo from './logo'

const GlobalStyle = createGlobalStyle`

  @font-face {

    font-family: "Dia";
    src:
      url("/Dia-Regular.woff") format("woff");
      
  }

  body{
    font-family: Dia, system-ui ,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    color: #323544;
    background: #C6C7CC;
  }

  H1,H2,H3,H4,H5,p{
    margin: 0;
  }

  a{
    text-decoration: none;
    color: inherit;
  }

`
const LogoWrapper = styled.a`
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .3s;
  svg {
    margin-right: .5rem;
    transition: all .3s;
  }
  span{
    opacity: 0;
    transition: all .3s;
    transform: translateX(-.1rem);
  }
  &:hover{
    opacity: .9;
    svg{
      transform: rotate(90deg);

    }
    span{
      opacity: 1;
      transform: translateX(0rem);

    }
  }
  @media (max-width: 40rem) {
      margin-bottom: 3rem;
   }
`
const PageTitle = styled.h1`
  font-size: 2.3rem;
  margin-bottom: 6rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  font-weight: 400;
  letter-spacing: -1px;
  @media (max-width: 64rem) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 40rem) {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
  
`

const AppWrapper = styled.div`
  max-width: 65rem;
  margin: 8vh 8vw;
  @media (max-width: 64rem) {
    margin: 4vh 4vw;
  }
`

const Loader = styled.div`
  margin-bottom: 8rem;
`

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
`

const ChipSelector = styled.p`
  padding: .5rem 1rem;
  border-radius: 8rem;
  margin-right: .5rem;
  margin-bottom: 1rem;
  border: ${props => props.active ? '1px solid transparent' : '1px solid #A5A7AF;'};
  cursor: pointer;
  min-width: max-content;
  transition: all .3s;
  ${props => props.active ? 'background: #5D606C; color: white; &:hover{opacity: .8;' : '&:hover{background: #D0D1D6;}'};
`

const AppsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem 2rem;
  margin-bottom: 8rem;
  @media (max-width: 64rem) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem 1rem;
    margin-bottom: 4rem;
  }
  @media (max-width: 40rem) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem;
  margin-bottom: 8rem;
  @media (max-width: 64rem) {
    grid-gap: 2rem;
    margin-bottom: 4rem;
  }
  @media (max-width: 40rem) {
    grid-template-columns: 1fr;
  }
  a {
    padding-top: 1rem;
    border-top: 1px solid #A5A7AF;
    &:hover{
      opacity: .5;
    }
    transition: all .3s;
  }

`

const Copyright = styled.p`
  font-size: .7rem;
  opacity: .7;
`



const FilterList = [
  {
    filter_name: 'All',
    filter_value: 'visible'
  },
  {
    filter_name: 'Augmented reality',
    filter_value: 'ar'
  },
  {
    filter_name: 'Photography',
    filter_value: 'photography'
  },
  {
    filter_name: 'Games',
    filter_value: 'game'
  }, 
  {
    filter_name: 'Utility',
    filter_value: 'utility'
  },
  {
    filter_name: 'Sound',
    filter_value: 'sound'
  }, 
]


class AppList extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        error: null,
        isLoaded: false,
        items: [],
        initialLoad: [],
        activeFilter: 0
    };
  }

  componentDidMount(){
    fetch("https://api.airtable.com/v0/appF1stIzbyDSWc3u/Table%201",{
      headers:{
        "Authorization" : "Bearer keyfiBRlV2wnNF6zf"
      }
    })
    .then(res => res.json())
    .then(
      (res) => {
        var records = res.records
        this.setState({
          isLoaded: true,
          items: records,
          initialLoad: records
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error
        })
      }
    )
  }

  // ArrayCheck(array,v){
  //   array.forEach(element => {
  //     if element
  //   })
  // }

  FilterIsActive(i){
    if(this.state.activeFilter === i){
      return true
    } else {
      return false
    }
  }

  FilterHandler(i){

    var filterValue = FilterList[i].filter_value;

    this.setState(
      {
        activeFilter: i,
        items: this.state.initialLoad.filter(x => x.fields.app_category.includes(filterValue))
      }
    )
  }

  render() {

      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return <Loader>Loading apps...</Loader>;
      } else {
        return (
          <div>
            <ChipsWrapper>
              {
                FilterList.map((item, i) => (
                  <ChipSelector key={i} active={this.FilterIsActive(i)} onClick={()=> this.FilterHandler(i)}> {item.filter_name} </ChipSelector>
                ))
              }
            </ChipsWrapper>
            <AppsGrid>
              { items.filter(item => item.fields.app_category.includes('visible')).map((item,i) => (
                    <AppCard
                      key={i}
                      AppIconUrl={item.fields.app_icon}
                      AppName={item.fields.app_name}
                      AppMaker={item.fields.app_maker}
                      AppPrice={item.fields.app_price}
                      AppLink={item.fields.app_link}
                      AppDesc={item.fields.app_description}

                    />
              ))
              }
            </AppsGrid>
          </div>
        );
      }
    }

}



function App() {
  return (
    <AppWrapper className="App">
      <GlobalStyle />
        <LogoWrapper href=".">
          <Logo />
          <span>Unusual</span>
        </LogoWrapper>
        <PageTitle>A curated directory of distinctive applications and games for iOS</PageTitle>
        <AppList />
        <Footer>
          <a class="typeform-share link" href="https://form.typeform.com/to/lXcLkC" data-mode="popup" data-submit-close-delay="0" target="_blank" >Submit an app </a> 
          <a target="_blank" href="https://twitter.com/unusualappsite"> Stay updated </a>
          <a target="_blank" href="https://enniodybeli.com">Made by @enniodybeli</a>
        </Footer>
        <Copyright> Unusual © All rights reserved — All apps are copyrights of their respective developers.</Copyright>
    </AppWrapper>

  );
}

export default App;
