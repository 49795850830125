import React from 'react';
import styled from 'styled-components'

const CardWrapper = styled.div`
  padding: 1.5rem;
  border: 1px solid #A5A7AF;
  border-radius: 1.5rem;
  transition: all .3s;
  cursor: pointer;
  &:hover,
  &:active{
    background: #D0D1D6; 
  }
`

const AppIcon = styled.div`
  background: white;
  height: 5rem;
  width: 5rem;
  border-radius: 1rem;
  margin-right: 1.5rem;
  background-image: url('${props => props.imageURL}');
  background-size: contain;
  background-repeat: no-repeat;

`

const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: .5fr 2fr;
`
const AppTitle = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: .3rem;
`
const AppMaker = styled.p`
  font-size: .82rem;
  opacity: .5;
  margin-bottom: 1rem;
`
const AppDescription = styled.p`
  font-size: .82rem;
  margin-bottom: .3rem;
`
const AppPrice = styled.p`
  font-size: .82rem;
  margin-bottom: .3rem;
  opacity: .5;
`



class AppCard extends React.Component {
  render() {
      function openLink(link){
    window.open(link,'_blank');
  }
    return <CardWrapper onClick={()=> openLink(this.props.AppLink)}>
            <FlexWrapper>
              <AppIcon imageURL={this.props.AppIconUrl}></AppIcon>
              <div>
                <AppTitle>{this.props.AppName}</AppTitle>
                <AppMaker>{this.props.AppMaker}</AppMaker>
                <AppDescription>{this.props.AppDesc}</AppDescription>
                <AppPrice>{this.props.AppPrice}</AppPrice>
                
              </div>
            </FlexWrapper>            
           </CardWrapper>;
  }
}

export default AppCard;
